/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentGoulet, selectCurrentGroup, selectGouletsByYear, selectStateClimate, setCurrentGoulet } from '../../store/mainReducer';
import { GouletProperties } from '../../models/GouletFeature';
import { ProdProperties } from '../../models/SiteFeature';

const StyledModal = styled(Modal)`
  right: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .custom-dialog {
    margin-right: 0;
    margin-top: 0;
    position: fixed;
    right: 0;
    max-width: 100%;
    height: 100vh;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    color: #ffffff;
    background: rgba(19, 98, 127, 1);
    height: 100%;
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
      color: #6c6c6c;
      text-decoration: none;
      }
    }
  }

  .modal-title {
    flex-basis: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    .item-1 {
      font-size: 24px;
      font-weight: 800;
    }
    .item-2 {
      font-size: 24px;
      font-weight: 400;
    }
    .item-3 {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .modal-header {
    padding: 0;
    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
`;
interface DescriptionModalProps {
  currentProd: ProdProperties;
}

const DescriptionModal: React.FC<DescriptionModalProps> = ({currentProd}) => {
    const dispatch = useAppDispatch();
    const currentGoulet = useAppSelector(selectCurrentGoulet);
    const goulets = useAppSelector(selectGouletsByYear);
    const currentGroup = useAppSelector(selectCurrentGroup);
    const stateClimat = useAppSelector(selectStateClimate);
    const currentGouletFeature = goulets.find(g => currentGoulet === g.properties.no);
    const handleCloseModal = (): void => {
        dispatch(setCurrentGoulet(0));
    };
    return (
        <StyledModal
            onHide={handleCloseModal}
            keyboard={false}
            centered={false}
            dialogClassName="custom-dialog col-md-2"
            show={currentGoulet !== 0}
            backdropClassName="rf-backdrop"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                <div className="row p-2">
          <div className="col-12 text-center pt-5">
          <h5>{currentProd.title}</h5>
            <h5> {currentProd.date}</h5>
          </div>
          <hr />
        </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12}>
                    <div className="row">
                      <div className="col-12 item-1 mb-3">
                      Groupe {currentGouletFeature?.properties.groupe}
                      </div>
                      <div className="col-12 item-2 mb-4">
                      Goulets {currentGroup}
                      </div>
                      <div className="col-12 item-3">
                      {currentGouletFeature?.properties[`descr_${stateClimat}` as keyof GouletProperties] !== undefined ? currentGouletFeature?.properties[`descr_${stateClimat}` as keyof GouletProperties] : `Pas de description ${stateClimat} pour le goulet ${currentGouletFeature?.properties.no}`}
                      </div>
                    </div>
                    </Col>
                </Row>
            </Modal.Body>
        </StyledModal>
    );
};

export default DescriptionModal;
