import { useEffect } from 'react';

import LeftAreaComponent from './components/left-area';
import ResponsiveBtnsComponent from './components/map-area/ResponsiveBtnsComponent';
import MapAreaComponent from './components/map-area';
import { useAppDispatch, useAppSelector } from './store/hooks';
import {
  setSegments,
  setSites,
  setGoulets,
  setGouletIcons,
  setZoomPoints,
  setProductions,
  selectLoading
} from './store/mainReducer';

import RightAreaComponent from './components/right-area';
import { getSegments, getSidebarProd, getSites, getGoulet, getGouletIcons, getZoomPoints } from './services';
import { Loading } from './shared/Loading';
import { CURRENT_DAY, CURRENT_YEAR } from './utils/enums';

function App() {
  const isLoading = useAppSelector(selectLoading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data_segment = getSegments(CURRENT_DAY.EJCS, CURRENT_YEAR.YEAR_2024, false);
    dispatch(setSegments(data_segment));

    const sites = getSites(CURRENT_DAY.EJCS, CURRENT_YEAR.YEAR_2024);
    dispatch(setSites(sites));
    const goulets = getGoulet();
    dispatch(setGoulets(goulets));

    const gouletIcons = getGouletIcons();
    dispatch(setGouletIcons(gouletIcons));
    const zoomPoints = getZoomPoints();
    dispatch(setZoomPoints(zoomPoints));

    const productions = getSidebarProd(CURRENT_YEAR.YEAR_2024);
    dispatch(setProductions(productions));
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row vh-100-md p-10">
          {/* <div className="col-12 d-block d-md-none">
          <ResponsiveSideAreaComponent />
        </div> */}
          <div className="col-12 order-1 order-md-0 col-md-2 d-none d-md-block sidebar">
            <LeftAreaComponent />
          </div>
          <div className="col-12 order-0 order-md-1 col-md-8 vh-md-100 vh-sm-100 pe-2 ps-2 shadow-sm">
            <MapAreaComponent />
          </div>
          <div className="col-12 order-2 order-md-2 col-md-2 d-none d-md-block sidebar">
            <RightAreaComponent />
          </div>
          <div className="col-12 d-block d-md-none">
            <ResponsiveBtnsComponent />
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
    </>
  );
}

export default App;
