import { GeoJsonLayer } from "@deck.gl/layers/typed";
import MER from "../../data/MER_MASQUE_WGS84.json";

const useWaterLayer = (): Array<GeoJsonLayer> => {
  const mer = new GeoJsonLayer({
    id: `euro-water`,
    data: MER as any,
    stroked: true,
    filled: true,
    pickable: true,
    getFillColor: [12, 57, 73], // #0C3949
    getLineColor: [35, 35, 35], // #232323
    lineWidthMinPixels: 3
  });
  return [mer];
};

export default useWaterLayer;