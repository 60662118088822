import { useAppSelector } from '../../store/hooks';
import { SiteFeature } from '../../models/SiteFeature';
import { selectSites, selectToggleConsommation } from '../../store/mainReducer';
import { HeatmapLayer } from '@deck.gl/aggregation-layers/typed';
import useDeviceDetect from '../../utils/useDeviceDetect';

const useHeatmapLayer = (): Array<HeatmapLayer<SiteFeature>> => {
  const visible = useAppSelector(selectToggleConsommation);
  const sites = useAppSelector(selectSites);

  const isMobile = useDeviceDetect();

  const layer = new HeatmapLayer<SiteFeature>({
    id: 'HeatmapLayer',
    data: sites,
    // threshold: 1,
    aggregation: 'SUM',
    getPosition: (d: SiteFeature) => d.position as any,
    getWeight: (d: SiteFeature) => d.properties.TOTAL_CONSO,
    colorRange: [
      [47, 28, 71],
      [87, 31, 102],
      [128, 32, 135],
      [167, 34, 132],
      [204, 36, 132],
      [233, 53, 122],
      [241, 66, 107],
    ],
    intensity: 0.5,
    radiusPixels: isMobile ? 5 : 48,
    threshold: 0.01,
    opacity: 0.8,
    visible,
    pickable: false,
  });

  return [layer];
};

export default useHeatmapLayer;
