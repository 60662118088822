import { PolygonLayer } from "@deck.gl/layers/typed";
import { GouletFeature } from '../../models/GouletFeature';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectCurrentGoulet, selectGouletsByYear, setCurrentGoulet } from '../../store/mainReducer';

const useGouletLayer = (): Array<PolygonLayer> => {
    const currentGoulet = useAppSelector(selectCurrentGoulet);
    const goulets_data = useAppSelector(selectGouletsByYear);
    const goulet = goulets_data.find(g => currentGoulet === g.properties.no);
    const dispatch = useAppDispatch();
    const handleClick = (d: any) => {
        dispatch(setCurrentGoulet(d.object.properties.no));
    }
    const goulets = new PolygonLayer<GouletFeature>({
        id: `goulets`,
        data: currentGoulet === 0 ? goulets_data : goulets_data.filter(ga => ga.properties.groupe === goulet?.properties.groupe),
        getPolygon: d => d.position,
        stroked: true,
        extruded: false,
        getFillColor: d => currentGoulet === 0 ? [231, 0, 0, 70] : [220, 220, 220, 80],
        getLineColor: d => currentGoulet === 0 ? [231, 0, 0] : [220, 220, 220],
        getLineWidth: 20,
        lineWidthMinPixels: 1,
        pickable: true,
        onClick: handleClick,
        autoHighlight: true,
        highlightColor: [255, 255, 255, 128]
    });
    return [goulets];
};

export default useGouletLayer;