/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectStateClimate, setCurrentZoom, setCurrentZoomActive, setSegments } from '../../store/mainReducer';
import { ZoomPointFeature, ZoomPointProperties } from '../../models/ZoomFeature';
import ZoomSwitcher from './ZoomSwitcher';
import { getSegments } from '../../services';
import { CURRENT_YEAR } from '../../utils/enums';

const StyledModal = styled(Modal)`
  right: 0;
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .custom-dialog {
    margin-right: 0;
    margin-top: 0;
    position: fixed;
    right: 0;
    max-width: 100%;
    height: 100vh;
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    color: #ffffff;
    background: rgba(19, 98, 127, 1);
    height: 100%;
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
      color: #6c6c6c;
      text-decoration: none;
      }
    }
  }

  .modal-title {
    flex-basis: 100%;
    font-size: 18px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    .item-1 {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .modal-header {
    padding: 0;
    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
    .form-check-input {
    height: 22px;
    }
`;
interface ZoomModalProps {
  currentZoom: ZoomPointFeature;
  handleClose: () => void;
}

const ZoomModal: React.FC<ZoomModalProps> = ({ currentZoom, handleClose }) => {
  const dispatch = useAppDispatch();
  const stateClimat = useAppSelector(selectStateClimate);
  const handleCloseModal = (): void => {
    dispatch(setCurrentZoom(null));
    dispatch(setCurrentZoomActive(false));
    dispatch(setSegments(getSegments(stateClimat, CURRENT_YEAR.YEAR_2030, false)));
    handleClose();
  };
  return (
    <StyledModal
      onHide={handleCloseModal}
      keyboard={false}
      centered={false}
      dialogClassName="custom-dialog col-md-2"
      show={currentZoom !== null}
      backdropClassName="rf-backdrop"
      backdrop={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="row p-2">
            <div className="col-12 text-center pt-5">
              <h5>Zone d'intérêt <b>{currentZoom.properties.Nom}</b></h5>
            </div>
            <hr />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <div className="row">
              <div className="col-12 item-1 mb-3">

                {currentZoom?.properties[`Msg_${stateClimat}` as keyof ZoomPointProperties] !== undefined ? currentZoom?.properties[`Msg_${stateClimat}` as keyof ZoomPointProperties] : `Pas de description ${stateClimat} pour le Zoom ${currentZoom?.properties.id}`}
              </div>
            </div>
          </Col>
          <Col sm={12}>
          <ZoomSwitcher />
          
          </Col>
        </Row>
      </Modal.Body>
    </StyledModal>
  );
};

export default ZoomModal;
