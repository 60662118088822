import { useAppSelector } from '../../store/hooks';
import {
  selectStateClimate,
  selectProductions,
  selectCurrentYear,
} from '../../store/mainReducer';
import { CURRENT_YEAR } from '../../utils/enums';
import DescriptionModal from './DescriptionModal';

const RightAreaComponent = () => {

  const selectedClimate = useAppSelector(selectStateClimate);
  const currentYear = useAppSelector(selectCurrentYear);
  
  const selectedProductions = useAppSelector(selectProductions);

  const currentProd = selectedProductions.filter(
    (elt: any) => elt.day === selectedClimate,
  )[0];

  return (
    <>
      {currentProd && currentYear === CURRENT_YEAR.YEAR_2030 && (
        <div className="row p-2">
          <div className="col-12 text-center pt-3">
            {currentProd.title && <h5>{currentProd.title}</h5>}
            {currentProd.date && <h5>{currentProd.date}</h5>}
          </div>
          <hr />
          <div className="col-12">
            <div
              className="p-2 text"
              dangerouslySetInnerHTML={{
                __html: currentProd.text,
              }}
            />
          </div>
        </div>
      )}
      {currentProd && <DescriptionModal currentProd={currentProd} />}
    </>
  );
};

export default RightAreaComponent;
