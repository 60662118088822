import { Accordion } from 'react-bootstrap';
import React from 'react';
import InfoModal from './InfoModal';
// import Info from '../../assets/images/info.svg';
import MultipleToggleSwitch from '../../shared/MultipleToggleSwitch';
import { SEASON_LABELS, WIND_INTERVAL_LABELS } from '../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styled from 'styled-components';
import {
  selectCurrentYear,
  selectCurrentZoomActive,
  selectProductions,
  selectStateClimate,
  setCurrentGoulet,
  setSegments,
  setSites,
  setStateClimate,
  toggleModalLegend,
} from '../../store/mainReducer';
import { isValidClimate, reBuildClimate, splitClimate } from '../../utils';
import { getSegments, getSites } from '../../services';
import BarByChart from '../../shared/BarByChart';

import Import from '../../assets/images/import.svg';
import Export from '../../assets/images/export.svg';
import Chart from '../../assets/images/chart.svg';
import useDeviceDetect from '../../utils/useDeviceDetect';

const StyledAccordion = styled(Accordion)`
  .accordion-item {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    border: none;
    margin-bottom: 1rem;
    border-radius: 0.6rem;
  }

  .accordion-button {
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .accordion-button:not(.collapsed) {
    color: rgba(255, 255, 255, 1);
    background-color: rgba(10, 90, 114, 1);
    box-shadow: none;
  }
  .accordion-button.collapsed {
    border: 2px solid transparent;
  }
  .accordion-button.collapsed:hover {
    border: 2px solid #0A5A72;
    border-radius: 5px;
  }
  .accordion-button::after {
    display: none;
  }
  .accordion-button {
    padding: 10px 6px;
    font-size: 1rem;
    color: rgba(255, 255, 255, 1);
    background-color: transparent;
  }
  .accordion-collapse {
    background-color: rgba(10, 90, 114, 1);
  }
  .accordion-body {
    padding-left: 0.8rem;
  }
`;

const LeftAreaComponent = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const isMobile = useDeviceDetect();

  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);
  const selectedProductions = useAppSelector(selectProductions);
  const currentClimate = splitClimate(selectedClimate);
  const currentZoomActive = useAppSelector(selectCurrentZoomActive);

  const currentProd = selectedProductions.filter(
    (elt: any) => elt.day === selectedClimate,
  )[0];

  const [currentWinter, setCurrentWinter] = React.useState(currentClimate[0]);
  const [currentWind, setCurrentWind] = React.useState(currentClimate[2]);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setCurrentWinter(currentClimate[0]);
    setCurrentWind(currentClimate[2]);
  }, [currentClimate, setCurrentWinter]);

  const handleCloseInfo = (): void => {
    setIsOpen(false);
  };
  /*
  const handleShowInfo = (): void => {
    setIsOpen(true);
  };
  */

  const handleSwitchClimate = (valueToggle: string, typeToggle: string) => {
    const newClimate = reBuildClimate(selectedClimate, valueToggle, typeToggle);

    if (isValidClimate(newClimate)) {
      dispatch(setStateClimate(newClimate));

      // update segement with climate
      const data_segment = getSegments(newClimate, currentYear, currentZoomActive);
      dispatch(setSegments(data_segment));

      const sites = getSites(newClimate, currentYear);
      dispatch(setSites(sites));
      dispatch(setCurrentGoulet(0));
      // dispatch(toggleModalLegend(false));
    }
  };

  return (
    <>
      <div className="row p-2" style={{position: 'relative', zIndex: 1500}}>
        {/*<div className="col-12 pt-3">
          <div className="row d-flex">
            <div className="col-10">
              <Form.Select disabled>
                <option value="0">{'Scénario - A-REF'}</option>
              </Form.Select>
            </div>
            <div className="col-2">
              <Button
                className="btn-cf p-1 mx-1"
                variant="link"
                onClick={handleShowInfo}
              >
                <img src={Info} className="icon" alt="" />
              </Button>
            </div>
          </div>
        </div>*/}
        <div className="col-12 mb-3">
          <div className="col-12  mb-1 mt-3">
            <h5>{'Type de journée'}</h5>
          </div>
          <MultipleToggleSwitch
            radios={SEASON_LABELS}
            nameToggle={'winter'}
            selectedToggle={
              currentWinter === 'E'
                ? SEASON_LABELS[0].value
                : SEASON_LABELS[1].value
            }
            onProductTypeChange={handleSwitchClimate}
          />

          <MultipleToggleSwitch
            radios={WIND_INTERVAL_LABELS}
            nameToggle={'wind'}
            selectedToggle={
              currentWind === 'C'
                ? WIND_INTERVAL_LABELS[0].value
                : WIND_INTERVAL_LABELS[1].value
            }
            onProductTypeChange={handleSwitchClimate}
          />
        </div>
        {!isMobile ? (
          <>
            {currentProd && (
              <StyledAccordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    {`Production : ${currentProd.total_prod} GW`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <BarByChart data={currentProd} />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    {`Consommation : ${currentProd.total_conso} GW`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <img
                      src={Chart}
                      alt="Chart"
                      className="img-conso"
                      style={{ width: '100%' }}
                    />
                    {/* <CustomAreaChart data={currentProd.conso} /> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    {`Solde des échanges : ${currentProd.solde} ${currentProd.sense}`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb-2">
                      <div className="col-2 text-start">
                        <img src={Export} alt="Export" />
                      </div>
                      <div className="col-10 fs-6">{`Export ${currentProd.export}`}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-2 text-start">
                        <img src={Import} alt="Import" />
                      </div>
                      <div className="col-10 fs-6">{`Import ${currentProd.import}`}</div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </StyledAccordion>
            )}
          </>
        ) : (
          <>
            {currentProd && (
              <div className="row">
                <div className="col-12 mt-3">
                  <h5>{`Production : ${currentProd.total_prod} GW`}</h5>
                </div>
                <div className="col-12">
                  <BarByChart data={currentProd} />
                </div>
                <div className="col-12 mt-3">
                  <h5>{`Consommation : ${currentProd.total_conso} GW`}</h5>
                </div>
                <div className="col-12">
                  <img
                    src={Chart}
                    alt="Chart"
                    className="img-conso"
                    style={{ width: '100%' }}
                  />
                </div>
                <div className="col-12 mt-3">
                  <h5>{`Solde des échanges : ${currentProd.solde} ${currentProd.sense}`}</h5>
                </div>
                <div className="col-12">
                  <div className="row mb-2">
                    <div className="col-2 text-start">
                      <img src={Export} alt="Export" />
                    </div>
                    <div className="col-10 fs-6">{`Export ${currentProd.export}`}</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-2 text-start">
                      <img src={Import} alt="Import" />
                    </div>
                    <div className="col-10 fs-6">{`Import ${currentProd.import}`}</div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <InfoModal show={modalIsOpen} handleClose={handleCloseInfo} />
    </>
  );
};

export default LeftAreaComponent;
