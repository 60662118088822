import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectToggleOther,
  selectToggleHydraulic,
  selectToggleNuclear,
  selectTogglePhotovoltaic,
  selectToggleThermic,
  selectToggleWind,
  toggleHydraulic,
  toggleNuclear,
  toggleOther,
  togglePhotovoltaic,
  toggleThermic,
  toggleWind,
} from '../../../store/mainReducer';

import ItemLegend from './ItemLegend';

interface ItemsProps {
  nameToggle: string;
  title: string;
  src: string;
}

const LegendMixElc: React.FC<ItemsProps> = ({ nameToggle, title, src }) => {
  const dispatch = useAppDispatch();
  const activeNuclear = useAppSelector(selectToggleNuclear);
  const activeWind = useAppSelector(selectToggleWind);
  const activePhotovoltaic = useAppSelector(selectTogglePhotovoltaic);
  const activeOther = useAppSelector(selectToggleOther);
  const activeHydraulic = useAppSelector(selectToggleHydraulic);
  const activeThermic = useAppSelector(selectToggleThermic);

  const handleSwitchToggle = () => {
    switch (nameToggle) {
      case 'wind':
        dispatch(toggleWind());
        break;
      case 'photovoltaic':
        dispatch(togglePhotovoltaic());
        break;
      case 'nuclear':
        dispatch(toggleNuclear());
        break;
      case 'hydraulic':
        dispatch(toggleHydraulic());
        break;
      case 'other':
        dispatch(toggleOther());
        break;
      case 'thermic':
        dispatch(toggleThermic());
        break;
    }
  };

  const switchActive = (nameToggle: string): boolean => {
    let active = false;
    if (nameToggle === 'wind') {
      active = activeWind;
    } else if (nameToggle === 'photovoltaic') {
      active = activePhotovoltaic;
    } else if (nameToggle === 'nuclear') {
      active = activeNuclear;
    } else if (nameToggle === 'thermic') {
      active = activeThermic;
    } else if (nameToggle === 'hydraulic') {
      active = activeHydraulic;
    } else if (nameToggle === 'other') {
      active = activeOther;
    }
    return active;
  };

  return (
    <div className="row  align-items-center">
      <div className="col-12">
        {nameToggle !== undefined && (
          <ItemLegend
            handleClick={handleSwitchToggle}
            active={switchActive(nameToggle)}
            title={title}
            src={src}
            displayEye={true}
          />
        )}
      </div>
    </div>
  );
};

export default LegendMixElc;
