import { SegmentFeature, SegmentProperties } from '../../models/SegmentFeature';
import { PathLayer } from '@deck.gl/layers/typed';
import { useAppSelector } from '../../store/hooks';
import { selectSegments } from '../../store/mainReducer';
import { PathStyleExtension } from '@deck.gl/extensions/typed';

const usePathLayer = (): Array<PathLayer<SegmentProperties>> => {
  const visibleSegments = useAppSelector(selectSegments);

  const paths_1 = new PathLayer({
    id: 'path',
    data: visibleSegments.filter(
      (elt: SegmentFeature) => elt.properties.type === '400',
    ),
    widthMinPixels: 2,
    getPath: (d) => d.path,
    getWidth: (d) => 2,
    getColor: (d) => d.color,
    opacity: 0.2,
    getDashArray: () => [6, 4], // Set dash array for the dashed line pattern
    dashJustified: true, // Adjust the rendering of the dashes to align with path ends
    extensions: [new PathStyleExtension({ dash: false })],
  });

  const paths_2 = new PathLayer({
    id: 'path-s1-2',
    data: visibleSegments.filter(
      (elt: SegmentFeature) => elt.properties.type !== '400',
    ),
    widthMinPixels: 2,
    getPath: (d) => d.path,
    getWidth: (d) => 2,
    getColor: (d) => [225, 89, 137],
    opacity: 0.2,
    getDashArray: () => [6, 4], // Set dash array for the dashed line pattern
    dashJustified: true, // Adjust the rendering of the dashes to align with path ends
    extensions: [new PathStyleExtension({ dash: true })],
  });

  return [paths_1, paths_2];
};

export default usePathLayer;
