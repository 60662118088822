import React from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  selectToggleNuclear,
  selectSites,
  selectToggleWind,
  selectToggleHydraulic,
  selectToggleThermic,
  selectTogglePhotovoltaic,
  selectToggleOther,
} from '../../store/mainReducer';
import { SiteFeature } from '../../models/SiteFeature';
import { IconLayer } from '@deck.gl/layers/typed';
import Nuclear from '../../assets/images/nuclear.svg';
import Hydraulic from '../../assets/images/Hydraulic.svg';
import Wind from '../../assets/images/Wind.svg';
import Thermic from '../../assets/images/thermic.svg';
import Photovoltaic from '../../assets/images/photovoltaic.svg';
import Other from '../../assets/images/Other.svg';

interface Tooltip {
  x: number;
  y: number;
  object?: any;
}

const useIconsLayer = (): Array<IconLayer<SiteFeature>> => {
  const visibleNuclear = useAppSelector(selectToggleNuclear);
  const visibleHydraulic = useAppSelector(selectToggleHydraulic);
  const visibleWind = useAppSelector(selectToggleWind);
  const visibleThermic = useAppSelector(selectToggleThermic);
  const visiblePhotovoltaic = useAppSelector(selectTogglePhotovoltaic);
  const visibleOther = useAppSelector(selectToggleOther);

  const [tooltip, setTooltip] = React.useState<Tooltip | null>(null);

  const sites = useAppSelector(selectSites);

  const layerHydraulic = new IconLayer<SiteFeature>({
    id: 'icon-layer-1',
    data: sites.filter((site) => site.properties.prod_hydro > 0),
    getIcon: (d: any) => ({
      url: Hydraulic,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visibleHydraulic,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  const layerWind = new IconLayer<SiteFeature>({
    id: 'icon-layer-2',
    data: sites.filter((site) => Number(site.properties.prod_eolien) > 0),
    getIcon: (d: any) => ({
      url: Wind,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visibleWind,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  const layerNuclear = new IconLayer<SiteFeature>({
    id: 'icon-layer-3',
    data: sites.filter((site) => Number(site.properties.prod_nucleaire) > 0),
    getIcon: (d: any) => ({
      url: Nuclear,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visibleNuclear,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  const layerThermic = new IconLayer<SiteFeature>({
    id: 'icon-layer-4',
    data: sites.filter((site) => site.properties.prod_thermique > 0),
    getIcon: (d: any) => ({
      url: Thermic,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visibleThermic,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  const layerPhotovoltaic = new IconLayer<SiteFeature>({
    id: 'icon-layer-5',
    data: sites.filter(
      (site) => Number(site.properties.prod_photovoltaique) > 0,
    ),
    getIcon: (d: any) => ({
      url: Photovoltaic,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visiblePhotovoltaic,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  const layerOther = new IconLayer<SiteFeature>({
    id: 'icon-layer-6',
    data: sites.filter((site) => Number(site.properties.prod_autre) > 0),
    getIcon: (d: any) => ({
      url: Other,
      width: 10,
      height: 10,
    }),
    sizeMinPixels: 10,
    getPosition: (d: any) => {
      return d.position;
    },
    getSize: (d) => 10,
    visible: visibleOther,
    pickable: true,
    onHover: (info) => {
      if (info.object) {
        setTooltip({
          x: info.x,
          y: info.y,
          object: info.object,
        });
      } else {
        setTooltip(null);
      }
    },
  });

  return [
    layerNuclear,
    layerHydraulic,
    layerWind,
    layerThermic,
    layerPhotovoltaic,
    layerOther,
  ];
};

export default useIconsLayer;
