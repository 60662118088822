import React from 'react'
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectCurrentZoomActive, setCurrentZoomActive, selectStateClimate, setSegments } from '../../store/mainReducer';
import { CURRENT_YEAR } from '../../utils/enums';
import { getSegments } from '../../services';

const StyledDiv = styled.div`
    width: 45px;
    height: 23px;
    position: relative;
    cursor: pointer;
    .switch-button {
        width: 45px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 4px;
        background-color: #999FA1;
        border-radius: 60px;
        transition: background-color 0.3s;
        &.on {
            background-color: #82D4F6;
        }
    }
    .switch-circle {
        width: 22.50px;
        height: 23px;
        position: absolute;
        left: 22.50px;
        top: 0;
        background: #FFFFFF;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
        border-radius: 23px;
        transition: transform 0.3s, background-color 0.3s;
        &.on {
            transform: translateX(-25px);
            background-color: #009CDF;
        }
    }
`;
const ZoomSwitcher = () => {
    const currentZoomActive = useAppSelector(selectCurrentZoomActive);
    const stateClimat = useAppSelector(selectStateClimate);
    const dispatch = useAppDispatch();
    const handleToggle = () => {
        dispatch(setCurrentZoomActive(!currentZoomActive));
        dispatch(setSegments(getSegments(stateClimat, CURRENT_YEAR.YEAR_2030, !currentZoomActive)));
    }
    return (
        <StyledDiv onClick={handleToggle}>
            <div className={`switch-button ${currentZoomActive ? 'on' : 'off'}`} />
            <div className={`switch-circle ${currentZoomActive ? 'on' : 'off'}`} />
        </StyledDiv>
    )
}

export default ZoomSwitcher;