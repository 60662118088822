import React from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { getLegendColor, getLegendName } from '../utils';

interface ComponentProps {
  data: any;
}

const StyledRow = styled(Row)`
  &.bar-chart-row {
    flex-wrap: wrap;
    column-gap: 0;
    height: 100%;
    align-items: center;
    row-gap: 20px;
    align-content: flex-start;
    @media (min-width: 576px) and (max-width: 992px) {
      align-content: flex-start;
    }
    .bar {
      height: 9.4rem;
    }
  }

  .bar-chart {
    background-color: var(--color-primary);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 1.8em;
    overflow: hidden;
  }

  .bar-data {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bar-text {
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .icon {
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    margin-right: 0.4rem;
  }
`;

const BarByChart: React.FC<ComponentProps> = ({ data }) => {
  return (
    <div className="col-12">
      <StyledRow className="bar-chart-row">
        <Col xs="2" md={'2'} className="">
          <div className="bar">
            <div className="bar-chart">
              {data &&
                data.productions.map((d: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="bar-data"
                      style={{
                        background: `${getLegendColor(d.sector)}`,
                        height: `${d.percent}%`,
                      }}
                    ></div>
                  );
                })}
            </div>
          </div>
        </Col>
        <Col xs="10" md="10">
          <Row>
            {data.productions.map((d: any, index: number) => {
              return (
                <Col md={'12'} key={index} className="bar-text">
                  <div
                    className="icon"
                    style={{ background: getLegendColor(d.sector) }}
                  ></div>
                  <span>
                    {getLegendName(d.sector)}&nbsp;({Math.round(d.percent) + '%'})
                  </span>
                </Col>
              );
            })}
          </Row>
        </Col>
      </StyledRow>
    </div>
  );
};

export default BarByChart;
