import { GeoJsonLayer } from "@deck.gl/layers/typed";
import FRANCE_CONTOUR from "../../data/FRANCE_CONTOUR_WGS84.json";



const useFranceBorderLayer = (): Array<GeoJsonLayer> => {
  const franceContour = new GeoJsonLayer({
    id: `franceContour`,
    data: FRANCE_CONTOUR as any,
    stroked: false,
    filled: false,
    pickable: false,
    getLineColor: [0, 166, 217], // #00A6D9
    lineWidthMinPixels: 2
  });
  return [franceContour];
};

export default useFranceBorderLayer;