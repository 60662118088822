import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

interface SideAreaComponentProps {
  radios: any;
  selectedToggle: string;
  nameToggle: string;
  disabled?: string;
  onProductTypeChange: (selectedCat: string, nameCat: string) => void;
}
const MultipleToggleSwitch: React.FC<SideAreaComponentProps> = ({
  radios,
  selectedToggle,
  nameToggle,
  disabled,
  onProductTypeChange,
}) => {
  const [isToggled, setIsToggled] = useState('0');

  React.useEffect(() => {
    setIsToggled(selectedToggle);
  }, [selectedToggle]);

  const handleSwitchType = (value: string) => {
    setIsToggled(value);
    onProductTypeChange(value, nameToggle);
  };

  return (
    <ToggleButtonGroup
      type="radio"
      name={`options-${nameToggle}`}
      id={`options-${nameToggle}`}
      size={'lg'}
      value={isToggled}
      className="mb-2 custom-toggle"
    >
      {radios.map((radio: any, idx: number) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="secondary"
          name="radio"
          value={radio.value}
          checked={isToggled === radio.value}
          onChange={(e) => handleSwitchType(e.target.value)}
          id={`${nameToggle}-${idx}`}
          disabled={disabled === radio.value || disabled === 'O'}
          style={{ width: '6rem' }}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default MultipleToggleSwitch;
