export enum LINE_TYPES {
  'LIT_CC' = 'LIT_CC',
  'LIT_225' = 'LIT_225',
  'LIT_400' = 'LIT_400',
  'TEST' = 'TEST',
}
export enum LINE_COLORS {
  'LIT_CC' = '#0018F9',
  'LIT_225' = '#800026',
  'LIT_400' = '#00FF00',
  'TEST' = '#FFFF00',
}

export enum CURRENT_YEAR {
  'YEAR_2024' = 'YEAR_2024',
  'YEAR_2030' = 'YEAR_2030',
  'YEAR_2035' = 'YEAR_2035',
  'YEAR_2040' = 'YEAR_2040',
}

export enum PROD_LABELS {
  NUCLEAR = 'Nucléaire',
  HYDRAULICS = 'Hydraulique',
  BIOENERGY = 'Bioénergies',
  THERMIC = 'Thermique',
  WIND_OFFSHORE = 'Eolien',
  WIND_ONSHORE = 'Eolien terrestre',
  WIND = 'Eolien',
  PHOTOVOLTAIC = 'Solaire',
  OTHER = 'Autres',
}
export enum PROD_COLORS {
  NUCLEAR = '#F4DD0B',
  HYDRAULICS = '#08AFE3',
  BIOENERGY = '#03BCAA',
  THERMIC = '#895729',
  WIND_OFFSHORE = '#53DED1',
  WIND = '#53DED1',
  PHOTOVOLTAIC = '#E8631D',
  OTHER = '#B8E1EA',
}

export enum ELECTRIC_MIX_LABELS {
  NUCLEAR = 'Nucléaire',
  HYDRAULICS = 'Hydraulique',
  OTHER = 'Autre',
  THERMIC = 'Thermique',
  WIND_OFFSHORE = 'Eolien',
  WIND_ONSHORE = 'Eolien terrestre',
  PHOTOVOLTAIC = 'Solaire',
}

export enum SEGMENT_LABELS {
  LINE_400KV = 'Ligne 400kV',
  LINE_CCHT = 'Ligne CCHT',
  FLUX = 'Flux',
}

export enum LEGEND_LABELS {
  PROD_SEUIL1 = 'Pas d’information',
  PROD_SEUIL2 = 'Non utilisée',
  PROD_SEUIL3 = 'Charge normale',
  PROD_SEUIL4 = 'Moyennement chargée',
  PROD_SEUIL5 = 'Fortement chargée',
  PROD_SEUIL6 = 'Surchargée',
}

export enum LEGEND_COLORS {
  PROD_SEUIL1 = '#989898',
  PROD_SEUIL2 = '#E15989',
  PROD_SEUIL3 = '#46DF11',
  PROD_SEUIL4 = '#FFBA00',
  PROD_SEUIL5 = '#FF5800',
  PROD_SEUIL6 = '#FF0F00',
  CONSO_SEUIL1 = '#231F2E',
  CONSO_SEUIL2 = '#3E2B4A',
  CONSO_SEUIL3 = '#523760',
}

export enum ELECTRIC_MIX_COLORS {
  NUCLEAR = '#FF9861',
  HYDRAULICS = '#0085AE',
  BIOENERGY = '#03BCAA',
  THERMIC = '#895729',
  WIND_OFFSHORE = '#A364D0',
  WIND_ONSHORE = '#CD99ED',
  PHOTOVOLTAIC = '#DAC505',
}

export enum CURRENT_DAY {
  'EJCS' = 'EJCS',
  'EJVS' = 'EJVS',
  'HNCC' = 'HNCC',
  'HNVC' = 'HNVC',
}
