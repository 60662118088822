import { GouletIconFeature } from './../../models/GouletFeature';
import { useAppSelector } from "../../store/hooks";
import { IconLayer } from "@deck.gl/layers/typed";
import Goulet from '../../assets/images/goulet.svg';
import { selectCurrentGoulet, selectGouletIconsByYear } from '../../store/mainReducer';

const useIconsLayer = (): Array<IconLayer<GouletIconFeature>> => {
    const currentGoulet = useAppSelector(selectCurrentGoulet);
    const icons = useAppSelector(selectGouletIconsByYear);
    const goulet = icons.find(g => currentGoulet === g.properties.no);
    const layer = new IconLayer<GouletIconFeature>({
        id: 'goulet-icon-layer',
        data: currentGoulet === 0 ? icons : icons.filter(ga => ga.properties.groupe === goulet?.properties.groupe),
        getIcon: (d: any) => ({
            url: Goulet,
            width: 32,
            height: 32
        }),
        sizeMinPixels: 32,
        // sizeMaxPixels: 32,
        getPosition: (d: any) => {
            return d.position
        },
        getColor: [255, 255, 0],
        pickable: true
    });
    return [layer];
};

export default useIconsLayer;