/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { SEGMENT_LABELS } from '../../../utils/enums';

import Line400kV from '../../../assets/images/Line400kV.svg';
import LigneCCHT from '../../../assets/images/LigneCCHT.svg';
import Flux from '../../../assets/images/flux.svg';
import LegendMixElc from './LegendMixElc';
import LegendSegment from './LegendSegment';
import Production from './Production';
import Consommation from './Consommation';
import { useAppSelector } from '../../../store/hooks';
import {
  selectProductions,
  selectStateClimate,
} from '../../../store/mainReducer';
import { getLegendName, getSrcByName } from '../../../utils';

interface LegendProps {
  show: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    color: #ffffff;
    background: rgba(19, 98, 127, 1);
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    max-height: 25rem;
    overflow-y: auto;

    @media (max-width: 576px) {
      max-height: 25rem;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(19, 98, 127, 0.9);
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: rgba(19, 98, 127, 0.9);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(19, 98, 127, 0.9);
      border: 1px solid rgba(21, 37, 44, 1);
    }
  }

  .custom-dialog {
    @media (min-width: 576px) {
      top: 26%;
      margin-left: 22%;
    }
  }
`;

const LegendModal: React.FC<LegendProps> = ({ show, handleClose }) => {
  const selectedClimate = useAppSelector(selectStateClimate);
  const selectedProductions = useAppSelector(selectProductions);

  const currentProd = selectedProductions.filter(
    (elt: any) => elt.day === selectedClimate,
  )[0];

  const handleCloseModal = (): void => {
    handleClose();
  };

  return (
    <StyledModal
      show={show}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      size={'sm'}
      dialogClassName="custom-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>{'Légende :'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
          <div className="col-10 fs-6 mb-2">
              {'Réseau'}
            </div>
          <LegendSegment
              nameToggle={'line400kV'}
              title={SEGMENT_LABELS.FLUX}
              src={Flux}
              displayEye={true}
              tooltip={false}
            />
            <LegendSegment
              nameToggle={'line400kV'}
              title={SEGMENT_LABELS.LINE_400KV}
              src={Line400kV}
              displayEye={false}
              tooltip={false}
            />
            <LegendSegment
              nameToggle={'LigneCCHT'}
              title={SEGMENT_LABELS.LINE_CCHT}
              src={LigneCCHT}
              displayEye={false}
              tooltip={true}
            />
            <Production />
            <Consommation />
            <div className="col-10 fs-6 mt-2 mb-2">
              {'Unités de production'}
            </div>
            {currentProd &&
              currentProd.productions.map((d: any, index: number) => {
                return (
                  <LegendMixElc
                    key={index}
                    nameToggle={d.id}
                    title={getLegendName(d.sector)}
                    src={getSrcByName(d.id)}
                  />
                );
              })}
          </Col>
        </Row>
      </Modal.Body>
    </StyledModal>
  );
};

export default LegendModal;
