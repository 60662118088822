export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidGFib3VhbWluZSIsImEiOiJjbHJ0Y2llbW8wMG1pMmtvNmpxajlmZjFxIn0.rxU8U4iQ3JmsRZ9dfXnfeg';
export const LOOP_TRIPS_LENGTH = 1000;

export const LINE_BY_YEARS: Record<string, number> = {
  YEAR_2024: 2024,
  YEAR_2030: 2030,
  YEAR_2035: 2035,
};

export const SWITSH_BY_YEAR: Record<number, string> = {
  2030: '400_AREF_2030_V3_WGS84',
  2035: '400_AREF_2035_V3_WGS84',
  2040: '400_AREF_2040_V3_WGS84',
};

export const NETWORK_LABELS = [
  { name: 'Minimum prévu', value: '0', checked: false },
  { name: 'Réseau cible', value: '1', checked: false },
];

export const SEASON_LABELS = [
  { name: 'été', value: 'E', disabled: false },
  { name: 'Hiver', value: 'H', disabled: false },
];

export const TIME_INTERVAL_LABELS = [
  { name: 'Jour', value: 'J', disabled: false },
  { name: 'Nuit', value: 'N', disabled: false },
];

export const WIND_INTERVAL_LABELS = [
  { name: 'Sans vent', value: 'C', disabled: false },
  { name: 'Venteuse', value: 'V', disabled: false },
];

export const WEATHER_TREND_LABELS = [
  { name: 'Ensoleillée', value: 'S', disabled: false },
  { name: 'Nuageuse', value: 'C', disabled: false },
];

export const DATA_CONSO_2024 = [
  {
    ref: 'EJCS',
    conso: [
      {
        hour: '00:00',
        value: Number('43,8'),
      },
      {
        hour: '01:00',
        value: Number('39,5'),
      },
      {
        hour: '02:00',
        value: Number('38,7'),
      },
      {
        hour: '03:00',
        value: Number('36,5'),
      },
      // {
      //   hour: '04:00',
      //   value: '35,4',
      // },
      // {
      //   hour: '05:00',
      //   value: '35,9',
      // },
      // {
      //   hour: '06:00',
      //   value: '38,2',
      // },
      // {
      //   hour: '07:00',
      //   value: '43,0',
      // },
      // {
      //   hour: '08:00',
      //   value: '46,4',
      // },
      // {
      //   hour: '09:00',
      //   value: '47,8',
      // },
      // {
      //   hour: '10:00',
      //   value: '48,1',
      // },
      // {
      //   hour: '11:00',
      //   value: '48,5',
      // },
      // {
      //   hour: '12:00',
      //   value: '48,7',
      // },
      // {
      //   hour: '13:00',
      //   value: '48,9',
      // },
      // {
      //   hour: '14:00',
      //   value: '46,4',
      // },
      // {
      //   hour: '15:00',
      //   value: '44,9',
      // },
      // {
      //   hour: '16:00',
      //   value: '44,1',
      // },
      // {
      //   hour: '17:00',
      //   value: '43,0',
      // },
      // {
      //   hour: '18:00',
      //   value: '43,9',
      // },
      // {
      //   hour: '19:00',
      //   value: '45,0',
      // },
      // {
      //   hour: '20:00',
      //   value: '44,5',
      // },
      // {
      //   hour: '21:00',
      //   value: '42,9',
      // },
      // {
      //   hour: '22:00',
      //   value: '43,2',
      // },
      // {
      //   hour: '23:00',
      //   value: '44,9',
      // },
    ],
  },
  {
    ref: 'EJVS',
    conso: [
      {
        hour: '00:00',
        value: '53,8',
      },
      {
        hour: '01:00',
        value: '50,7',
      },
      {
        hour: '02:00',
        value: '50,3',
      },
      {
        hour: '03:00',
        value: '48,2',
      },
      {
        hour: '04:00',
        value: '46,9',
      },
      {
        hour: '05:00',
        value: '47,8',
      },
      {
        hour: '06:00',
        value: '51,5',
      },
      {
        hour: '07:00',
        value: '56,2',
      },
      {
        hour: '08:00',
        value: '59,3',
      },
      {
        hour: '09:00',
        value: '59,2',
      },
      {
        hour: '10:00',
        value: '58,8',
      },
      {
        hour: '11:00',
        value: '58,1',
      },
      {
        hour: '12:00',
        value: '58,7',
      },
      {
        hour: '13:00',
        value: '57,3',
      },
      {
        hour: '14:00',
        value: '55,3',
      },
      {
        hour: '15:00',
        value: '53,3',
      },
      {
        hour: '16:00',
        value: '52,3',
      },
      {
        hour: '17:00',
        value: '51,2',
      },
      {
        hour: '18:00',
        value: '53,3',
      },
      {
        hour: '19:00',
        value: '58,8',
      },
      {
        hour: '20:00',
        value: '58,4',
      },
      {
        hour: '21:00',
        value: '55,2',
      },
      {
        hour: '22:00',
        value: '52,3',
      },
      {
        hour: '23:00',
        value: '53,6',
      },
    ],
  },
  {
    ref: 'HNCC',
    conso: [
      {
        hour: '00:00',
        value: '51,4',
      },
      {
        hour: '01:00',
        value: '48,2',
      },
      {
        hour: '02:00',
        value: '47,7',
      },
      {
        hour: '03:00',
        value: '45,6',
      },
      {
        hour: '04:00',
        value: '43,8',
      },
      {
        hour: '05:00',
        value: '43,1',
      },
      {
        hour: '06:00',
        value: '44,4',
      },
      {
        hour: '07:00',
        value: '46,6',
      },
      {
        hour: '08:00',
        value: '48,3',
      },
      {
        hour: '09:00',
        value: '51,0',
      },
      {
        hour: '10:00',
        value: '52,7',
      },
      {
        hour: '11:00',
        value: '52,4',
      },
      {
        hour: '12:00',
        value: '52,5',
      },
      {
        hour: '13:00',
        value: '51,4',
      },
      {
        hour: '14:00',
        value: '49,3',
      },
      {
        hour: '15:00',
        value: '48,3',
      },
      {
        hour: '16:00',
        value: '46,8',
      },
      {
        hour: '17:00',
        value: '46,0',
      },
      {
        hour: '18:00',
        value: '48,1',
      },
      {
        hour: '19:00',
        value: '53,7',
      },
      {
        hour: '20:00',
        value: '52,4',
      },
      {
        hour: '21:00',
        value: '49,8',
      },
      {
        hour: '22:00',
        value: '48,5',
      },
      {
        hour: '23:00',
        value: '50,8',
      },
    ],
  },
  {
    ref: 'HNVC',
    conso: [
      {
        hour: '00:00',
        value: '54,1',
      },
      {
        hour: '01:00',
        value: '50,3',
      },
      {
        hour: '02:00',
        value: '50,0',
      },
      {
        hour: '03:00',
        value: '48,1',
      },
      {
        hour: '04:00',
        value: '46,2',
      },
      {
        hour: '05:00',
        value: '46,3',
      },
      {
        hour: '06:00',
        value: '49,4',
      },
      {
        hour: '07:00',
        value: '54,8',
      },
      {
        hour: '08:00',
        value: '57,6',
      },
      {
        hour: '09:00',
        value: '59,3',
      },
      {
        hour: '10:00',
        value: '60,1',
      },
      {
        hour: '11:00',
        value: '60,8',
      },
      {
        hour: '12:00',
        value: '61,5',
      },
      {
        hour: '13:00',
        value: '59,8',
      },
      {
        hour: '14:00',
        value: '59,0',
      },
      {
        hour: '15:00',
        value: '58,8',
      },
      {
        hour: '16:00',
        value: '57,5',
      },
      {
        hour: '17:00',
        value: '57,0',
      },
      {
        hour: '18:00',
        value: '59,1',
      },
      {
        hour: '19:00',
        value: '64,3',
      },
      {
        hour: '20:00',
        value: '61,9',
      },
      {
        hour: '21:00',
        value: '58,3',
      },
      {
        hour: '22:00',
        value: '56,5',
      },
      {
        hour: '23:00',
        value: '57,9',
      },
    ],
  },
];

export const DATA_CONSO_2030 = [
  {
    ref: 'EJCS',
    conso: [
      {
        hour: '00:00',
        value: '43,5',
      },
      {
        hour: '01:00',
        value: '43,2',
      },
      {
        hour: '02:00',
        value: '41,7',
      },
      {
        hour: '03:00',
        value: '42,0',
      },
      {
        hour: '04:00',
        value: '42,0',
      },
      {
        hour: '05:00',
        value: '43,1',
      },
      {
        hour: '06:00',
        value: '43,1',
      },
      {
        hour: '07:00',
        value: '44,8',
      },
      {
        hour: '08:00',
        value: '47,8',
      },
      {
        hour: '09:00',
        value: '57,0',
      },
      {
        hour: '10:00',
        value: '59,3',
      },
      {
        hour: '11:00',
        value: '63,6',
      },
      {
        hour: '12:00',
        value: '66,2',
      },
      {
        hour: '13:00',
        value: '67,9',
      },
      {
        hour: '14:00',
        value: '70,4',
      },
      {
        hour: '15:00',
        value: '68,7',
      },
      {
        hour: '16:00',
        value: '65,2',
      },
      {
        hour: '17:00',
        value: '61,0',
      },
      {
        hour: '18:00',
        value: '59,3',
      },
      {
        hour: '19:00',
        value: '52,2',
      },
      {
        hour: '20:00',
        value: '49,3',
      },
      {
        hour: '21:00',
        value: '49,0',
      },
      {
        hour: '22:00',
        value: '48,9',
      },
      {
        hour: '23:00',
        value: '46,0',
      },
    ],
  },
  {
    ref: 'EJVS',
    conso: [
      {
        hour: '00:00',
        value: '45,4',
      },
      {
        hour: '01:00',
        value: '44,0',
      },
      {
        hour: '02:00',
        value: '42,0',
      },
      {
        hour: '03:00',
        value: '41,7',
      },
      {
        hour: '04:00',
        value: '42,3',
      },
      {
        hour: '05:00',
        value: '41,3',
      },
      {
        hour: '06:00',
        value: '43,2',
      },
      {
        hour: '07:00',
        value: '46,2',
      },
      {
        hour: '08:00',
        value: '48,9',
      },
      {
        hour: '09:00',
        value: '50,9',
      },
      {
        hour: '10:00',
        value: '52,9',
      },
      {
        hour: '11:00',
        value: '57,2',
      },
      {
        hour: '12:00',
        value: '61,3',
      },
      {
        hour: '13:00',
        value: '66,3',
      },
      {
        hour: '14:00',
        value: '66,0',
      },
      {
        hour: '15:00',
        value: '65,5',
      },
      {
        hour: '16:00',
        value: '62,2',
      },
      {
        hour: '17:00',
        value: '53,5',
      },
      {
        hour: '18:00',
        value: '50,3',
      },
      {
        hour: '19:00',
        value: '50,3',
      },
      {
        hour: '20:00',
        value: '44,6',
      },
      {
        hour: '21:00',
        value: '41,2',
      },
      {
        hour: '22:00',
        value: '42,5',
      },
      {
        hour: '23:00',
        value: '44,3',
      },
    ],
  },
  {
    ref: 'HNCC',
    conso: [
      {
        hour: '00:00',
        value: '45,2',
      },
      {
        hour: '01:00',
        value: '45,5',
      },
      {
        hour: '02:00',
        value: '44,4',
      },
      {
        hour: '03:00',
        value: '41,9',
      },
      {
        hour: '04:00',
        value: '43,3',
      },
      {
        hour: '05:00',
        value: '43,9',
      },
      {
        hour: '06:00',
        value: '50,1',
      },
      {
        hour: '07:00',
        value: '54,2',
      },
      {
        hour: '08:00',
        value: '56,0',
      },
      {
        hour: '09:00',
        value: '57,5',
      },
      {
        hour: '10:00',
        value: '61,7',
      },
      {
        hour: '11:00',
        value: '64,9',
      },
      {
        hour: '12:00',
        value: '66,9',
      },
      {
        hour: '13:00',
        value: '67,7',
      },
      {
        hour: '14:00',
        value: '69,0',
      },
      {
        hour: '15:00',
        value: '66,5',
      },
      {
        hour: '16:00',
        value: '61,9',
      },
      {
        hour: '17:00',
        value: '55,2',
      },
      {
        hour: '18:00',
        value: '55,1',
      },
      {
        hour: '19:00',
        value: '56,8',
      },
      {
        hour: '20:00',
        value: '53,6',
      },
      {
        hour: '21:00',
        value: '49,5',
      },
      {
        hour: '22:00',
        value: '48,3',
      },
      {
        hour: '23:00',
        value: '45,4',
      },
    ],
  },
  {
    ref: 'HNVC',
    conso: [
      {
        hour: '00:00',
        value: '66,3',
      },
      {
        hour: '01:00',
        value: '65,5',
      },
      {
        hour: '02:00',
        value: '65,2',
      },
      {
        hour: '03:00',
        value: '65,2',
      },
      {
        hour: '04:00',
        value: '69,8',
      },
      {
        hour: '05:00',
        value: '69,9',
      },
      {
        hour: '06:00',
        value: '68,3',
      },
      {
        hour: '07:00',
        value: '65,7',
      },
      {
        hour: '08:00',
        value: '67,4',
      },
      {
        hour: '09:00',
        value: '69,8',
      },
      {
        hour: '10:00',
        value: '73,3',
      },
      {
        hour: '11:00',
        value: '79,4',
      },
      {
        hour: '12:00',
        value: '83,4',
      },
      {
        hour: '13:00',
        value: '82,9',
      },
      {
        hour: '14:00',
        value: '81,0',
      },
      {
        hour: '15:00',
        value: '75,1',
      },
      {
        hour: '16:00',
        value: '69,5',
      },
      {
        hour: '17:00',
        value: '69,1',
      },
      {
        hour: '18:00',
        value: '69,7',
      },
      {
        hour: '19:00',
        value: '68,8',
      },
      {
        hour: '20:00',
        value: '68,4',
      },
      {
        hour: '21:00',
        value: '66,0',
      },
      {
        hour: '22:00',
        value: '68,3',
      },
      {
        hour: '23:00',
        value: '66,9',
      },
    ],
  },
];

export const getSwitchConso = (year: string, day: string) => {
  switch (year) {
    case 'YEAR_2024':
      return DATA_CONSO_2024.filter((elt: any) => elt.ref === day)[0].conso;
    case 'YEAR_2030':
      return DATA_CONSO_2030.filter((elt: any) => elt.ref === day)[0].conso;
  }
};
