import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  selectCurrentYear,
  selectCurrentZoomActive,
  selectStateClimate,
  setCurrentYear,
  setProductions,
  setSegments,
  setSites,
} from '../../store/mainReducer';
import { LINE_BY_YEARS } from '../../utils/constants';
import { CURRENT_YEAR } from '../../utils/enums';
// import db from '../../data/dbByYears.json';
import { getSegments, getSidebarProd, getSites } from '../../services';

const SliderByYear = () => {
  const currentYear = useAppSelector(selectCurrentYear);
  const selectedClimate = useAppSelector(selectStateClimate);
  const currentZoomActive = useAppSelector(selectCurrentZoomActive);

  const dispatch = useAppDispatch();

  const min = 2024; // Minimum value of the slider
  const max = 2030; // Maximum value of the slider
  const step = 6; // Step value of the slider

  const getCurrentYear = (str: string) => {
    return CURRENT_YEAR[str as keyof typeof CURRENT_YEAR];
  };

  // Function to handle change in slider
  const handleChange = (e: any) => {
    if (e.target.value) {
      const selectedYear = getCurrentYear(`YEAR_${e.target.value}`);
      dispatch(setCurrentYear(selectedYear));

      const data_segment = getSegments(selectedClimate, selectedYear, currentZoomActive);
      dispatch(setSegments(data_segment));
  
      const sites = getSites(selectedClimate, selectedYear);
      dispatch(setSites(sites));
      
      const productions = getSidebarProd(selectedYear);
      dispatch(setProductions(productions));
    }
  };

  return (
    <Form>
      <Form.Group>
        <div className="d-flex justify-content-between">
          {Array.from({ length: (max - min) / step + 1 }, (_, index) => (
            <small key={index} className="text-muted">
              {min + index * step}
            </small>
          ))}
        </div>
        <Form.Range
          min={min}
          max={max}
          step={step}
          value={LINE_BY_YEARS[currentYear]}
          onChange={handleChange}
        />
      </Form.Group>
    </Form>
  );
};

export default SliderByYear;
