/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import Infos from '../../assets/images/infos.png';

interface InfoProps {
  show: boolean;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    border-radius: 2px;
    color: #ffffff;
    background: rgba(19, 98, 127, 1);
  }
  .modal-footer {
    border-top: none;

    .btn {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &.btn-link {
        color: #6c6c6c;
        text-decoration: none;
      }
    }
  }

  .modal-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 10.879px;
  }
  .modal-body {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }
  .img-infos {
    width: 100%;
  }
`;

const InfoModal: React.FC<InfoProps> = ({ show, handleClose }) => {
  const handleCloseModal = (): void => {
    handleClose();
  };

  return (
    <StyledModal
      show={show}
      onHide={handleCloseModal}
      backdrop="static"
      keyboard={false}
      size={'xl'}
      dialogClassName="custom-dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Informations :</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <p>
              Dans le contexte de la préparation par l’État de la Stratégie
              française pour l’énergie et le climat (SFEC), vous pouvez
              sélectionner des scénarii illustrant des trajectoires de
              transformation du système énergétique vers la neutralité carbone
              (période 2023-2035).
            </p>
            <p>
              Le scénario A-Réf s’inscrit dans une trajectoire prospective
              d’accélération réussie. Dans ce scénario, l’industrie française se
              développe en s’appuyant de manière préférentielle sur des énergies
              décarbonées.{' '}
            </p>
          </Col>
          <Col sm={12}>
            <img src={Infos} alt="Infos" className="img-infos" />
          </Col>
        </Row>
      </Modal.Body>
    </StyledModal>
  );
};

export default InfoModal;
