/* eslint-disable array-callback-return */
import { sizes } from './breakpoints';

import Nuclear from '../assets/images/nuclear.svg';
import Wind from '../assets/images/Wind.svg';
import Hydraulic from '../assets/images/Hydraulic.svg';
import Thermic from '../assets/images/thermic.svg';
import Photovoltaic from '../assets/images/photovoltaic.svg';
import Other from '../assets/images/Other.svg';
import { PROD_COLORS, PROD_LABELS } from './enums';
import { GouletFeature } from '../models/GouletFeature';

export function generateRandomNumber(n: number): number {
  return Math.floor(Math.random() * n) + 1;
}

export const getPieHeight = (wdth: number) => {
  if (wdth <= sizes.md) {
    return 170;
  } else {
    return 234;
  }
};

export const renderSwitchType = (type: string): boolean => {
  switch (type) {
    case 'E':
      return true;
    case 'D':
      return false;
    default:
      return false;
  }
};

// type ContOut = '0' | '1' | '2';
// type Combinations =
//   | 'EJVC'
//   | 'EJVS'
//   | 'EJCC'
//   | 'EJCS'
//   | 'ENVC'
//   | 'ENVS'
//   | 'ENCC'
//   | 'ENCS'
//   | 'HJVC'
//   | 'HJVS'
//   | 'HJCC'
//   | 'HJCS'
//   | 'HNVC'
//   | 'HNVS'
//   | 'HNCC'
//   | 'HNCS';
// type InvavlidCombinations = 'ENVS' | 'HNVS' | 'HNCS' | 'ENCS';

// const truthTable = {
//   EJVC: true,
//   EJVS: true,
//   EJCC: true,
//   EJCS: true,
//   ENVC: true,
//   ENVS: false,
//   ENCC: true,
//   ENCS: false,
//   HJVC: true,
//   HJVS: true,
//   HJCC: true,
//   HJCS: true,
//   HNVC: true,
//   HNVS: false,
//   HNCC: true,
//   HNCS: false,
// };

// // Nuit ensoleillée = Nuit Nuageuse(couvert) => (_N_S => _N_C)
// const replacedTable = {
//   ENVS: 'ENVC',
//   HNVS: 'HNVC',
//   HNCS: 'HNCC',
//   ENCS: 'ENCC',
// } as const;

// const isCombinationInvalid = (str: Combinations): str is InvavlidCombinations =>
//   !truthTable[str];

// const Correspondence = (selectedVariableName: Combinations) => {
//   return isCombinationInvalid(selectedVariableName)
//     ? replacedTable[selectedVariableName]
//     : selectedVariableName;
// };

// // toggleWinter  => ÉTÉ    = false / HIVER   = true
// // toggleDay   => JOUR   = false / NUIT    = true
// // toggleWind   => VENT   = false / CALME   = true
// // toggleSun => SOLEIL = false / COUVERT = true
// export const defineSwitshClimate =
//   (geoJsonFeature: any) =>
//   (
//     toggleWinter: boolean,
//     toggleDay: boolean,
//     toggleWind: boolean,
//     toggleSun: boolean,
//   ): ContOut => {
//     const value1 = toggleWinter ? 'H' : 'E';
//     const value2 = toggleDay ? 'J' : 'N';
//     const value3 = toggleWind ? 'V' : 'C';
//     const value4 = toggleSun ? 'S' : 'C';

//     const id = `${value1}${value2}${value3}${value4}` as const;
//     const selectedProperty = `Cont_${Correspondence(id)}` as const;
//     return geoJsonFeature.properties[selectedProperty];
//   };

const Combinations = ['EJCS', 'EJVS', 'HNCC', 'HNVC'];

export const splitClimate = (status: string) => {
  return status.split('');
};

export const isValidClimate = (str: string) => Combinations.includes(str);

export const reBuildClimate = (
  selectedClimate: string,
  value: string,
  type: string,
): string => {
  let value1 = splitClimate(selectedClimate)[0];
  let value2 = splitClimate(selectedClimate)[1];
  let value3 = splitClimate(selectedClimate)[2];
  let value4 = splitClimate(selectedClimate)[3];

  switch (type) {
    case 'winter':
      value1 = value1 === value ? value1 : value;
      value2 = value === 'E' ? 'J' : 'N';
      value4 = value === 'E' ? 'S' : 'C';
      break;
    case 'day':
      value2 = value2 === value ? value2 : value;
      break;
    case 'wind':
      value3 = value3 === value ? value3 : value;
      break;
    case 'sun':
      value4 = value4 === value ? value4 : value;
      break;
  }

  const selected = `${value1}${value2}${value3}${value4}` as const;
  return selected;
};

export const checkClimate = (status: string) => {
  return status.split('');
};

// export const getCurrentDay = (day: string): any => {
//   switch (day) {
//     case 'EJCS':
//       return DB_EJCS;
//     case 'EJVS':
//       return DB_EJVS;
//     case 'HNCC':
//       return DB_HNCC;
//     case 'HNVC':
//       return DB_HNVC;
//   }
// };

export const getCurrentColor = (state: number): any => {
  switch (state) {
    case -1:
      return [152, 152, 152];
    case 0:
      return [225, 89, 137];
    case 1:
      return [70, 223, 17];
    case 2:
      return [255, 186, 0];
    case 3:
      return [255, 88, 0];
    case 4:
      return [255, 15, 0];
  }
};

export const getSrcByName = (nameToggle: string): any => {
  switch (nameToggle) {
    case 'photovoltaic':
      return Photovoltaic;
    case 'nuclear':
      return Nuclear;
    case 'wind':
      return Wind;
    case 'hydraulic':
      return Hydraulic;
    case 'other':
      return Other;
    case 'thermic':
      return Thermic;
  }
};

export const getLegendColor = (str: string) => {
  return PROD_COLORS[str as keyof typeof PROD_COLORS];
};

export const getLegendName = (str: string) => {
  return PROD_LABELS[str as keyof typeof PROD_LABELS];
};

export const getGroupGoulets = (goulets: GouletFeature[], index: number) => {
  const currentGouletFeature = goulets.find(g => g.properties.no === index);
  return goulets.filter(d => d.properties.groupe === currentGouletFeature?.properties.groupe).map((elt => elt.properties.no)).join(',');
}