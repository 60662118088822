
import { useAppDispatch } from '../../../store/hooks';
import {
  toggleProduction,
} from '../../../store/mainReducer';

import ProductionItem from './ProductionItem';
import { LEGEND_COLORS, LEGEND_LABELS } from '../../../utils/enums';

const Production = () => {
  const dispatch = useAppDispatch();
  // const active = useAppSelector(selectToggleProduction);
  const handleClick = () => {
    dispatch(toggleProduction());
  };
  return (
    <div className="row  align-items-center">
      <div className="col-12">
        <div className="row">
          <div
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
            className="col-12"
          >
            <div className="row pt-3 pb-2">
              <div className="col-12 fs-6">{'Etat des lignes'}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <ProductionItem
              color={LEGEND_COLORS.PROD_SEUIL3}
              label={LEGEND_LABELS.PROD_SEUIL3}
            />
          </div>
          <div className="col-12">
            <ProductionItem
              color={LEGEND_COLORS.PROD_SEUIL4}
              label={LEGEND_LABELS.PROD_SEUIL4}
            />
          </div>
          <div className="col-12">
            <ProductionItem
              color={LEGEND_COLORS.PROD_SEUIL5}
              label={LEGEND_LABELS.PROD_SEUIL5}
            />
          </div>
          <div className="col-12">
            <ProductionItem
              color={LEGEND_COLORS.PROD_SEUIL6}
              label={LEGEND_LABELS.PROD_SEUIL6}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Production;
