import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { SegmentFeature } from '../models/SegmentFeature';
import { ProdProperties, SiteFeature } from '../models/SiteFeature';
import { GouletFeature, GouletIconFeature, GouletProperties } from '../models/GouletFeature';
import { CURRENT_DAY, CURRENT_YEAR } from '../utils/enums';
import { ZoomPointFeature } from '../models/ZoomFeature';
import { getGroupGoulets } from '../utils';

interface ToggleMixElec {
  ligneCCHT: boolean;
  wind: boolean;
  photovoltaic: boolean;
  nuclear: boolean;
  thermic: boolean;
  hydraulic: boolean;
  line400kV: boolean;
  other: boolean;
}

interface ToggleModalActive {
  legend: boolean; // Réseau
  simulator: boolean;
}

const initToggleModalActive: ToggleModalActive = {
  legend: false,
  simulator: false,
};

const initialToggleMixElec: ToggleMixElec = {
  ligneCCHT: true,
  line400kV: true,
  wind: true,
  photovoltaic: true,
  nuclear: true,
  thermic: true,
  other: true,
  hydraulic: true,
};

interface MainState {
  currentYear: string;
  segments: SegmentFeature[];
  sites: SiteFeature[];
  goulets: {
    polygons: GouletFeature[],
    icons: GouletIconFeature[],
    currentGoulet: number,
    currentGroup: string,
  };
  zoom: {
    zoomPoints: ZoomPointFeature[],
    currentZoom: ZoomPointFeature | null,
    currentZoomActive: boolean,
  }
  productions: ProdProperties[];
  toggleMixElec: ToggleMixElec;
  stateClimate: string;
  toggleModalActive: ToggleModalActive;
  toggleProd: boolean;
  toggleFlux: boolean;
  consommation: boolean;
  isLoading: boolean;
}

const initialState: MainState = {
  currentYear: CURRENT_YEAR.YEAR_2024,
  segments: [],
  sites: [],
  goulets: {
    polygons: [],
    icons: [],
    currentGoulet: 0,
    currentGroup: '0'
  },
  zoom: {
    zoomPoints: [],
    currentZoom: null,
    currentZoomActive: false
  },
  productions: [],
  toggleProd: true,
  consommation: true,
  toggleFlux: true,
  toggleMixElec: initialToggleMixElec,
  stateClimate: CURRENT_DAY.EJCS,
  toggleModalActive: initToggleModalActive,
  isLoading: false,
};

export const mainSlice = createSlice({
  name: 'Main',
  initialState,
  reducers: {
    setCurrentYear: (state, action) => {
      
      state.currentYear = action.payload;
    },
    setLoading: (state, action) => {
      state.currentYear = action.payload;
    },
    setStateClimate: (state, action) => {
      state.stateClimate = action.payload;
    },
    toggleFlux: (state) => {
      state.toggleFlux = !state.toggleFlux;
    },
    toggleModalLegend: (state, action) => {
      state.toggleModalActive.legend = action.payload;
    },
    toggleModalSimulator: (state, action) => {
      state.toggleModalActive.simulator = action.payload;
    },
    setSegments: (state, action) => {
      console.log(action.type);
      state.segments = action.payload;
    },
    setSites: (state, action) => {
      state.sites = action.payload;
    },
    setGoulets: (state, action) => {
      state.goulets.polygons = action.payload;
    },
    setGouletIcons: (state, action) => {
      state.goulets.icons = action.payload;
    },
    setZoomPoints: (state, action) => {
      state.zoom.zoomPoints = action.payload;
    },
    setCurrentZoom: (state, action) => {
      state.zoom.currentZoom = action.payload;
    },
    setCurrentZoomActive: (state, action) => {
      state.zoom.currentZoomActive = action.payload;
    },
    setCurrentGoulet: (state, action) => {
      state.goulets.currentGoulet = action.payload;
    },
    setProductions: (state, action) => {
      state.productions = action.payload;
    },
    toggleProduction: (state) => {
      state.toggleProd = !state.toggleProd;
    },
    toggleConsommation: (state) => {
      state.consommation = !state.consommation;
    },
    toggleWind: (state) => {
      state.toggleMixElec.wind = !state.toggleMixElec.wind;
    },
    togglePhotovoltaic: (state) => {
      state.toggleMixElec.photovoltaic = !state.toggleMixElec.photovoltaic;
    },
    toggleNuclear: (state) => {
      state.toggleMixElec.nuclear = !state.toggleMixElec.nuclear;
    },
    toggleHydraulic: (state) => {
      state.toggleMixElec.hydraulic = !state.toggleMixElec.hydraulic;
    },
    toggleOther: (state) => {
      state.toggleMixElec.other = !state.toggleMixElec.other;
    },
    toggleThermic: (state) => {
      state.toggleMixElec.thermic = !state.toggleMixElec.thermic;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCurrentYear,
  setStateClimate,
  toggleModalLegend,
  toggleModalSimulator,
  setSegments,
  setSites,
  setGoulets,
  setGouletIcons,
  setZoomPoints,
  setCurrentGoulet,
  setCurrentZoom,
  setCurrentZoomActive,
  setProductions,
  toggleConsommation,
  toggleProduction,
  toggleWind,
  togglePhotovoltaic,
  toggleNuclear,
  toggleOther,
  toggleHydraulic,
  toggleThermic,
  toggleFlux,
} = mainSlice.actions;

export const selectLoading = (state: RootState) => state.main.isLoading;

export const selectStateClimate = (state: RootState) => state.main.stateClimate;
export const selectCurrentYear = (state: RootState) => state.main.currentYear;
export const selectFlux = (state: RootState) => state.main.toggleFlux;

export const selectModalLegend = (state: RootState) =>
  state.main.toggleModalActive.legend;

export const selectModalSimulator = (state: RootState) =>
  state.main.toggleModalActive.simulator;

export const selectSegments = (state: RootState) => state.main.segments;
export const selectSites = (state: RootState) => state.main.sites;
export const selectGoulets = (state: RootState) => state.main.goulets.polygons;
export const selectGouletsByYear = createSelector(
  [selectGoulets, selectCurrentYear, selectStateClimate],
  (goulets, currentYear, climate) => {
    console.log(climate);
    if(CURRENT_YEAR.YEAR_2024 === currentYear) {
      return [];
    }
    return goulets.filter((g) => g.properties[climate as keyof GouletProperties] === true);
    
  }
);
const selectGouletIcons = (state: RootState) => state.main.goulets.icons;
export const selectGouletIconsByYear = createSelector(
  [selectGouletIcons, selectCurrentYear, selectStateClimate],
  (icons, currentYear, climate) => {
    if(CURRENT_YEAR.YEAR_2024 === currentYear) {
      return [];
    }
    return icons.filter((g) => g.properties[climate as keyof GouletProperties] === true);;
    
  }
);
export const selectZoomPoints = (state: RootState) => state.main.zoom.zoomPoints;
export const selectCurrentZoom = (state: RootState) => state.main.zoom.currentZoom;
export const selectCurrentZoomActive = (state: RootState) => state.main.zoom.currentZoomActive;
export const selectCurrentGoulet = (state: RootState) => state.main.goulets.currentGoulet;
export const selectCurrentGroup = createSelector(
  [selectGoulets, selectCurrentGoulet],
  (goulets, currentGoulet) => {
    return getGroupGoulets(goulets, currentGoulet);
    
  }
);
export const selectProductions = (state: RootState) => state.main.productions;

export const selectToggleProduction = (state: RootState) =>
  state.main.toggleProd;

export const selectToggleWind = (state: RootState) =>
  state.main.toggleMixElec.wind;
export const selectTogglePhotovoltaic = (state: RootState) =>
  state.main.toggleMixElec.photovoltaic;
export const selectToggleNuclear = (state: RootState) =>
  state.main.toggleMixElec.nuclear;
export const selectToggleThermic = (state: RootState) =>
  state.main.toggleMixElec.thermic;
export const selectToggleOther = (state: RootState) =>
  state.main.toggleMixElec.other;
export const selectToggleHydraulic = (state: RootState) =>
  state.main.toggleMixElec.hydraulic;
export const selectToggleConsommation = (state: RootState) =>
  state.main.consommation;

export default mainSlice.reducer;
