import { GeoJsonLayer } from "@deck.gl/layers/typed";
import Regions from "../../data/REGIONS_V2_WGS84.json";

const useRegionsLayer = (): Array<GeoJsonLayer> => {
  const regionsFrance = new GeoJsonLayer({
    id: `regions`,
    data: Regions as any,
    stroked: true,
    filled: true,
    getFillColor: () => [16, 24, 29], // #10181D
    pickable: true,
    getLineColor: [0, 104, 136], // #006888
    lineWidthMinPixels: 2
  });
  return [regionsFrance];
};

export default useRegionsLayer;