import * as React from 'react';
import { DeckProps, PickingInfo } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import Map, { useControl, MapRef, LngLatBoundsLike } from 'react-map-gl';
import useDeviceWidth from '../../utils/useDeviceDetect';

import { MAPBOX_ACCESS_TOKEN } from '../../utils/constants';
import * as turf from '@turf/turf';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import MapStyle from '../../assets/map-styles/style.json';
import { fromJS } from 'immutable';
import usePathLayer from '../layers/usePathLayer';
import LegendModal from './legend';
import useCustomTripsLayer from '../layers/useCustomTripsLayer';
import useHeatmapLayer from '../layers/useHeatmapLayer';
import useIconsLayer from '../layers/useIconsLayer';
import useGouletLayer from '../layers/useGouletLayer';
import useEuropeLayer from '../layers/useEuropeLayer';
import useFranceBorderLayer from '../layers/useFranceBorderLayer';
import useWaterLayer from '../layers/useWaterLayer';
import useRegionsLayer from '../layers/useRegionsLayer';
import {
  selectModalSimulator,
  selectZoomPoints,
  setCurrentZoom,
  toggleModalSimulator,
  selectCurrentZoom,
  selectCurrentYear,
  selectGoulets,
} from '../../store/mainReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import SliderByYear from './SliderByYear';
import useGouletIconsLayer from '../layers/useGouletIconsLayer';
import { GouletIconFeature } from '../../models/GouletFeature';
import { IconLayer } from "@deck.gl/layers/typed";
import ZoomPoint from '../../assets/images/charger.svg';
import InitZoom from '../../assets/images/init-zoom.svg';
import ZoomModal from './ZoomModal';
import { ZoomPointFeature } from '../../models/ZoomFeature';
import { CURRENT_YEAR } from '../../utils/enums';
import { getGroupGoulets } from '../../utils';

const StyledDiv = styled.div`
  .btn-cf1 {
    background: #e9f7fc;
    color: #004d66;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 600;
    text-decoration: none;
  }
  .position-absolute {
    z-index: 11;
  }
`;

function DeckGLOverlay(props: DeckProps) {
  const deck = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}
export default function MapAreaComponent() {
  const isMobile = useDeviceWidth();
  const selectedModel = useAppSelector(selectModalSimulator);
  const currentZoom = useAppSelector(selectCurrentZoom);
  const currentYear = useAppSelector(selectCurrentYear);
  const goulets = useAppSelector(selectGoulets);
  const dispatch = useAppDispatch();

  const franceBounds: LngLatBoundsLike = [
    [-5.142222, 41.364],
    [9.561556, 51.089062],
  ];

  const mapRef = React.useRef<MapRef>() as React.Ref<MapRef>;

  const pathLayer = usePathLayer();
  const tripsLayer = useCustomTripsLayer();
  const franceBorder = useFranceBorderLayer();
  const europeLayer = useEuropeLayer();
  const waterLayer = useWaterLayer();
  const heatMap = useHeatmapLayer();
  const iconsLayer = useIconsLayer();
  const regionsLayer = useRegionsLayer();
  const gouletsLayer = useGouletLayer();
  const gouletIcons = useGouletIconsLayer();
  const icons = useAppSelector(selectZoomPoints);
  
  const zoomToPoint = (e: PickingInfo) => {
    dispatch(setCurrentZoom(e.object));
    const clickPoint = turf.point([e.object.position[0], e.object.position[1]]);

    const currentMapRef = (mapRef as any).current.getMap();
    const bbox = turf.bbox(turf.buffer(clickPoint, 0.1));
    currentMapRef.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]], {
      padding: { top: 10, bottom: 10, left: 10, right: 10 },
      maxZoom: 8
    });
  }
  const zoomLayer = new IconLayer<GouletIconFeature>({
    id: 'zoom-icon-layer',
    data: icons.filter((p: ZoomPointFeature) => p.properties.Nom === "SSFOS" && (CURRENT_YEAR.YEAR_2030 === currentYear)),
    getIcon: (d: any) => ({
        url: ZoomPoint,
        width: 32,
        height: 32
    }),
    sizeMinPixels: 32,
    getPosition: (d: any) => {
        return d.position
    },
    getColor: [255, 255, 0],
    pickable: true,
    onClick: zoomToPoint
  });
  const handleInitZoom = () => {
    dispatch(setCurrentZoom(null));
    const currentMapRef = (mapRef as any).current.getMap();
    currentMapRef.fitBounds(franceBounds as LngLatBoundsLike, {
      padding: {padding: 30}, duration: 1000
    });
  }

  const handleCloseLegend = (): void => {
    dispatch(toggleModalSimulator(false));
  };
  const handleShowLegend = (): void => {
    dispatch(toggleModalSimulator(true));
  };

  return (
    <>
      <Map
        initialViewState={{
          longitude: 2.5,
          latitude: 46.5,
          bounds: franceBounds,
          fitBoundsOptions: { padding: isMobile ? 0 : 30 },
        }}
        mapStyle={fromJS(MapStyle) as any}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        ref={mapRef}
        dragRotate={false}
        dragPan={false}
        touchZoomRotate={false}
        doubleClickZoom={false}
        scrollZoom={false}
      >
        <DeckGLOverlay
          layers={[
            
            ...regionsLayer,
            ...heatMap,
            ...waterLayer,
            ...europeLayer,
            ...franceBorder,

            ...pathLayer,
            ...tripsLayer,
            ...iconsLayer,
            ...gouletsLayer,
            ...gouletIcons,
            zoomLayer
            // ...polygonLayer,
          ]}
          getTooltip={(info: PickingInfo) => {
            if(info.object && info?.layer?.id === 'goulets') {
              return {
                html: `<strong>Groupe ${info.object.properties.groupe}</strong><br />Goulet ${getGroupGoulets(goulets, info.object.properties.no)}`,
                style: {
                  borderRadius: "17px",
                  backgroundColor: "#FFFFFF",
                  color: 'black',
                  textAlign: 'center'

                }
              }  
            }
            return null;
          }}
        />
        <StyledDiv
          className="position-absolute"
          style={{
            bottom: '10px',
            left: isMobile ? '1px' : '10px',
            zIndex: 10,
          }}
        >
          {!isMobile ? (
            <Button
              className="btn-cf"
              variant="link"
              onClick={handleShowLegend}
            >
              <span className="icon icon-button-legend">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </Button>
          ) : (
            <Button
              className="btn-cf1"
              variant="link"
              onClick={handleShowLegend}
            >
              {'Légende'}
            </Button>
          )}
        </StyledDiv>

        <div
          className="btn-year position-absolute wrapper"
          style={{ bottom: '0', right: '0', zIndex: '11' }}
        >
          <SliderByYear />
        </div>
      <Button className='btn-cf position-absolute p-0 z-3' style={{top: '10px', left: '10px', width: 'auto', zIndex: 1500}} variant="secondary" onClick={handleInitZoom}>
        <img src={InitZoom} alt='' width="32px" height="32px"/>
      </Button>
      </Map>
      <LegendModal show={selectedModel} handleClose={handleCloseLegend} />
      {currentZoom !== null && <ZoomModal currentZoom={currentZoom} handleClose={handleInitZoom} />}
    </>
  );
}
