import { LineString, MultiLineString, Polygon, Point } from 'geojson';
import { SegmentFeature, SegmentProperties } from '../models/SegmentFeature';
import { LOOP_TRIPS_LENGTH } from '../utils/constants';
import { getCurrentColor } from '../utils';
import {
  SiteFeature,
  SiteProperties,
} from '../models/SiteFeature';
import { GouletFeature, GouletIconFeature, GouletProperties } from '../models/GouletFeature';
import { ZoomPointFeature, ZoomPointProperties } from '../models/ZoomFeature';
import SITES_2024_EJCS from '../data/sites/2024_IMAGRID_AREF_EJCS.json';
import SITES_2024_EJVS from '../data/sites/2024_IMAGRID_AREF_EJVS.json';
import SITES_2024_HNCC from '../data/sites/2024_IMAGRID_AREF_HNCC.json';
import SITES_2024_HNVC from '../data/sites/2024_IMAGRID_AREF_HNVC.json';

import SITES_2030_EJCS from '../data/sites/2030_IMAGRID_AREF_EJCS.json';
import SITES_2030_EJVS from '../data/sites/2030_IMAGRID_AREF_EJVS.json';
import SITES_2030_HNCC from '../data/sites/2030_IMAGRID_AREF_HNCC.json';
import SITES_2030_HNVC from '../data/sites/2030_IMAGRID_AREF_HNVC.json';

import LINES_2024 from '../data/LINES_2024.json';
import LINES_2030 from '../data/LINES_2030.json';
import LINES_2030_ZOOM from '../data/goulet/LINES_2030_ZOOM.json';

import FRANCE_CONTOUR from '../data/FRANCE_CONTOUR_WGS84.json';

import DATA_PROD_2030 from '../data/dataProd_2030.json';
import DATA_PROD_2024 from '../data/dataProd_2024.json';
import DATA_GOULET from '../data/goulet/GOULETS_V2_20240708.json';
import DATA_GOULET_ICONS from '../data/goulet/GOULETS_V2_POINTS_20240708.json';
import DATA_ZOOM_POINTS from '../data/goulet/ZIPE_PTS.json';

const getSegmentsByDay = (day: string, year: string, currentZoomActive: boolean): any => {
  if(year === 'YEAR_2024') {
    return LINES_2024.filter((elt) => elt.name === day)[0];
  } else if(year === 'YEAR_2030' && !currentZoomActive) {
    return LINES_2030.filter((elt) => elt.name === day)[0];
  } else {
    return LINES_2030_ZOOM.filter((elt) => elt.name === day)[0];
  }
};

export const getSitesByDay = (day: string, year: string): any => {
  if (year === 'YEAR_2024') {
    switch (day) {
      case 'EJCS':
        return SITES_2024_EJCS;
      case 'EJVS':
        return SITES_2024_EJVS;
      case 'HNCC':
        return SITES_2024_HNCC;
      case 'HNVC':
        return SITES_2024_HNVC;
    }
  } else if (year === 'YEAR_2030') {
    switch (day) {
      case 'EJCS':
        return SITES_2030_EJCS;
      case 'EJVS':
        return SITES_2030_EJVS;
      case 'HNCC':
        return SITES_2030_HNCC;
      case 'HNVC':
        return SITES_2030_HNVC;
    }
  }
};

export const getSegments = (
  current_day: string,
  year: string,
  currentZoomActive: boolean,
): SegmentFeature => {
  const segments = getSegmentsByDay(current_day, year, currentZoomActive);
  const data_segment = segments.features
    .filter((elt: any) => elt.geometry !== null)
    .map(
      (elt: GeoJSON.Feature<LineString, SegmentProperties>): SegmentFeature => {
        return {
          year: segments.year,
          name: segments.name,
          scenario: segments.name,
          dashed: false,
          properties: { ...elt.properties },
          path: elt.geometry.coordinates,
          timestamps: [0, LOOP_TRIPS_LENGTH],
          color: getCurrentColor(elt.properties.etat),
        };
      },
    );
  return data_segment;
};

export const getSeuil = (conso: number) => {
  if (conso >= 450) {
    return 10;
  }
  if (conso < 450 && conso >= 340) {
    return 20;
  }
  return 30;
};

export const getSidebarProd = (year: string) => {
  switch (year) {
    case 'YEAR_2024':
      return DATA_PROD_2024;
    case 'YEAR_2030':
      return DATA_PROD_2030;
  }
};

export const getSites = (current_day: string, year: string): SiteFeature => {
  const data_sites = getSitesByDay(current_day, year);
  const sites = (data_sites as any).features
    .filter((elt: any) => elt.geometry !== null)
    .map((elt: GeoJSON.Feature<Polygon, SiteProperties>): SiteFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates,
        size: 1000,
        visible: true,
        seuil: getSeuil(elt.properties.TOTAL_CONSO),
        conso: elt.properties.TOTAL_CONSO,
        tooltip: {
          position: elt.geometry.coordinates,
          content: 'Détails:',
        },
      };
    });
  return sites;
};

export const getGoulet = (): GouletFeature => {
  const goulets = (DATA_GOULET as any).features
    .map((elt: GeoJSON.Feature<Polygon, GouletProperties>): GouletFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates
      };
    });
  return goulets;
};
export const getGouletIcons = (): GouletIconFeature => {
  const goulets = (DATA_GOULET_ICONS as any).features
    .map((elt: GeoJSON.Feature<Point, GouletProperties>): GouletIconFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates
      };
    });
  return goulets;
};

export const getZoomPoints = (): ZoomPointFeature => {
  const goulets = (DATA_ZOOM_POINTS as any).features
    .map((elt: GeoJSON.Feature<Point, ZoomPointProperties>): ZoomPointFeature => {
      return {
        properties: { ...elt.properties },
        position: elt.geometry.coordinates
      };
    });
  return goulets;
};